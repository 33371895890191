import React, { useRef, useCallback, useState } from "react";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { toast } from "react-toastify";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from '../hooks/useQuery';
import { ListDataFile } from "../components/ListDataFile";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import SharepointFilePicker from "../components/SharepointFilePicker";
import SharepointSitePicker from "../components/SharepointSitePicker";
import IconOneDrive from "../images/icons/one-drive.svg";

export default function EditMascotDataSharepoint() {
  const { currentOrganization, currentUser } = useAuth();
  const { embeddingTask, addTask, taskList } = useTasks();
  const { mascot, updateDataLastModified } = useEditMascot();
  const pickerRef = useRef();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const [isShownSitePicker, setShownSitePicker] = useState(false);

  const authorizeUser = async () => {
    const result = await servicesClient.authorizeSharepoint(currentOrganization._id, window.location.href);
    if (result.ok) {
      window.location.href = result.data.authUrl;
    }
  };

  const onSelect = useCallback(
    async (files) => {
      const filteredFiles = files.filter((file) => {
        if (
          mascot.data?.uploads.filter((doc) => doc.type === "sharepoint").some((u) => u.name === file.name) ||
          taskList
            .filter((doc) => (doc.type === "addSharepointTask" || doc.type === "addSharepointPageTask")&& doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => <><b>{file.name}</b>: Duplicate files cannot be uploaded.</>);
          return false;
        }
        return true;
      });
      const result = await servicesClient.dataAddSharepoint(mascot._id, filteredFiles, currentUser._id);
      if (!result.ok || result.data.error) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => <><b>{filteredFiles[0].id}</b>: {String(result.originalError)}.</>);
        return;
      }
      result.data?.forEach((item) => addTask(item));
    },
    [mascot, currentUser, addTask, taskList]
  );

  const onSelectSites = useCallback(async (pages) => {
    const result = await servicesClient.dataAddSharepointPages(mascot._id, pages, currentUser._id);
    if (!result.ok || result.data.error) {
      console.log("Upload Error! " + result.originalError);
      toast.error(() => <><b>{pages[0].id}</b>: {String(result.originalError)}.</>);
      return;
    }
    result.data?.forEach((item) => addTask(item));
  }, [mascot, currentUser, addTask]);

  const browse = async () => {
    const result = await servicesClient.getSharePointBaseUrl(currentOrganization._id);
    if (!result.ok) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "sharepoint").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else if (pickerRef.current) {
      pickerRef.current.launchSharepointPicker(result.data);
    }
  };

  const browseSites = async () => {
    const result = await servicesClient.getSharePointBaseUrl(currentOrganization._id);
    if (!result.ok) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "sharepoint").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else if (pickerRef.current) {
      setShownSitePicker(true);
    }
  };

  const checkUpdates = async () => {
    setShowUpdateLoader(true);
    const result = await servicesClient.dataCheckUpdatesSharepoint(mascot._id);
    setShowUpdateLoader(false);
    if (result.ok) {
      for (const [key, value] of Object.entries(result.data)) {
        value === null && toast.error(() => <><b>Cannot update file:</b> "{mascot.data?.uploads.find(u => u._id === key)?.name}" was deleted from Sharepoint.</>);
      }
      updateDataLastModified(result.data);
    }
  };

  const handleRefreshDoc = async (upload) => {
    const result = await servicesClient.dataUpdateSharepoint(mascot._id, upload._id, currentUser._id);
    result.ok && addTask({ ...result.data, meta: { filename: upload.name, mimeType: upload.mimeType } });
  };

  const getFileAction = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && (
        <i
          className="icon-reload"
          onClick={() => {
            handleRefreshDoc(doc);
          }}
        ></i>
      )}
    </>
  );

  const getFileActionPermanent = (doc) => (
    <>
      {!embeddingTask &&
        doc.lastModified > doc.lastUpdate &&
        doc.sharepointAccount === currentOrganization.sharepointConnected && (
          <span className="meta small warning">This file has changes, please update</span>
        )}
      {!embeddingTask && doc.sharepointAccount !== currentOrganization.sharepointConnected && (
        <span className="meta small">Not synced (account changed)</span>
      )}
    </>
  );

  const docsToUpdate = (docs) => {
    return docs.filter(
      (doc) =>
        doc.type === "sharepoint" &&
        doc.lastModified > doc.lastUpdate &&
        doc.sharepointAccount === currentOrganization.sharepointConnected
    );
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane content-wrapper full-width">
        {!currentOrganization.oneDriveConnected ? (
          <>
            <h3>
              <img src={IconOneDrive} alt={IconOneDrive}></img> OneDrive Business & Sharepoint
            </h3>

            <p>Connect your Microsoft Business account to sync files from OneDrive and Sharepoint Pages to your mascot.</p>
          </>
        ) : (
          <>
            <h3>
              <img src={IconOneDrive} alt={IconOneDrive}></img> OneDrive Business & Sharepoint
            </h3>
          </>
        )}

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "sharepoint").length > 0 ? (
          <ListDataFile
            title="Uploaded Files"
            searchLabel="Search files"
            getFileAction={getFileAction}
            getFileActionPermanent={getFileActionPermanent}
            disabled={!!embeddingTask}
            handleRefreshDoc={handleRefreshDoc}
            docsToUpdate={docsToUpdate}
            buttonBar={
              <>
                <button className="small" onClick={browse} disabled={embeddingTask}>
                  {currentOrganization.sharepointConnected ? "Browse Files" : "Connect Account"}
                </button>
                { currentOrganization.sharepointConnected && <button className="small" onClick={browseSites} disabled={embeddingTask}>SharePoint Pages</button> }

                {currentOrganization.sharepointConnected && (
                  <button className="small" disabled={showUpdateLoader} onClick={checkUpdates}>
                    {showUpdateLoader && <Loader classNames="small"></Loader>}
                    <span>Check For Updates</span>
                  </button>
                )}
              </>
            }
            files={
              mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "sharepoint").reverse()
            }
          ></ListDataFile>
        ) : (
          <div className="file-list-btn-bar">
            <button className="small" onClick={browse} disabled={embeddingTask}>
              {currentOrganization.sharepointConnected ? "Browse Files" : "Connect Account"}
            </button>
            { currentOrganization.sharepointConnected && <button className="small" onClick={browseSites} disabled={embeddingTask}>SharePoint Pages</button> }
          </div>
        )}

        {isShownSitePicker && (
          <SharepointSitePicker
            isShown={isShownSitePicker}
            setShown={setShownSitePicker}
            onSelect={onSelectSites}
            subdomain={currentOrganization.zendeskConnected}
          />
        )}

        <SharepointFilePicker ref={pickerRef} onSelect={onSelect} />
        
        <Modal
          title="Connect to Sharepoint"
          size="small"
          isOpen={showConfirmModal}
          close={() => setShowConfirmModal(false)}
          action={
            <>
              <button className="action" onClick={authorizeUser}>
                Continue
              </button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </>
          }
        >
          <p>Existing Sharepoint documents will not sync if connecting with a different Sharepoint account.</p>
        </Modal>
        
        <Modal
          title={`Authentication Error ${query.get('error')}`}
          isOpen={query.has('error')}
          close={() => history.push(location.pathname)}
        >
          <p>Authentication failed with the following error: <br/><code>{query.get('error_description')}</code></p>
        </Modal>
      </div>
    </div>
  );
}
