import React, { useState, useEffect, useImperativeHandle, forwardRef, useCallback } from "react";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";

const SharepointFilePicker = forwardRef(({ onSelect }, ref) => {

  const [port, setPort] = useState(null);
  const [win, setWin] = useState(null);
  const { currentOrganization } = useAuth();
  const [resources, setResources] = useState({});

  const params = {
    sdk: "8.0",
    messaging: {
      channelId: crypto.randomUUID(),
      origin: process.env.REACT_APP_URL_APP
    },
    authentication: {},
    entry: {
      /*sharepoint: {
        byPath: {
          list: "https://wiseox-my.sharepoint.com/personal/francesco_wiseox_onmicrosoft_com/Documents"
        }
      }*/
      oneDrive: {
        recent: {}
      }
    },
    typesAndSources: {
      mode: "files"
    },
    selection: {
      mode: "multiple"
    },
    search: {
      enabled: true
    }
  }

  const getToken = useCallback(async (command) => {
    const resource = command.resource.replace(/\/$/, '');
    if (resources[resource]) {
      return resources[resource];
    }
    const result = await servicesClient.getSharepointScopedToken(currentOrganization._id, command.resource + '/.default');
    if (result.ok) {
      setResources((prevResources) => ({
        ...prevResources,
        [resource]: result.data.accessToken,
      }));
      return result.data.accessToken;
    }
  }, [currentOrganization, resources]);

  useEffect(() => {
    const messageListener = async (message) => {
      switch (message.data.type) {
        case "command":
          port.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });
          const command = message.data.data;
          switch (command.command) {
            case "authenticate":
              const token = await getToken(command);
              if (typeof token !== "undefined" && token !== null) {
                port.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token: token,
                  }
                });
              } else {
                console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
              }
              break;
            case "close":
              win.close();
              break;
            case "pick":
              onSelect(command.items);
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });
              win.close();
              break;
            default:
              console.warn(`Unsupported command: ${JSON.stringify(command)}`);
              port.postMessage({
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command
                },
                isExpected: true,
              });
              break;
          }
          break;
        default:
          break;
      }
    };

    if (port) {
      port.addEventListener("message", messageListener);
      port.start();

      return () => {
        port.removeEventListener("message", messageListener);
      };
    }
  }, [port, onSelect, win, getToken]);

  async function launchSharepointPicker({ baseUrl }) {
    const newWin = window.open("", "Picker", "width=800,height=600");
    setWin(newWin);

    const authToken = await getToken({
      resource: baseUrl.replace(/\/$/, ''),
      command: "authenticate",
      type: "SharePoint",
    });

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(params),
    });
    const url = `${baseUrl.replace(/\/$/, '')}/_layouts/15/FilePicker.aspx?${queryString}`;

    const form = newWin.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");
    newWin.document.body.append(form);

    const input = newWin.document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "access_token");
    input.setAttribute("value", authToken);
    form.appendChild(input);

    form.submit();
    
    window.addEventListener("message", (event) => {
      if (event.source && event.source === newWin) {
        const message = event.data;
        if (message.type === "initialize" && message.channelId === params.messaging.channelId) {
          setPort(event.ports[0]);
          event.ports[0].postMessage({ type: "activate" });
        }
      }
    });
  }

  useImperativeHandle(ref, () => ({
    launchSharepointPicker,
  }));

  return <></>;
});

export default SharepointFilePicker;