import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

// Components
import MascotImage from "./MascotImage";
import Modal from "./Modal";
import Loader from "./Loader";
import Logo from "../images/logo-wiseox.svg";

// Context
import { useAuth } from "../hooks/useAuth";
import { useConversations } from "../hooks/useConversations";
import { useIntersect } from "../hooks/useIntersect";
import { useMascot } from "../hooks/useMascot";

export default function MascotSidebar({ mascotInit, editing, sidebarOpen, setSidebarOpen, loading, showBadge = false }) {
  const history = useHistory();
  // Context
  const { mascot } = useMascot();
  const { conversations, selectedConversation, selectConversation, createConversation, loadMoreConversation } =
    useConversations();
  const { isAuthenticated, currentOrganization } = useAuth();

  // Utils
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [lazyLoader] = useIntersect({ rootMargin: "0px", threshold: 1.0, onIntersect: handleIntersect });

  // Conversations
  const [today, setToday] = useState([]);
  const [last7days, setLast7days] = useState([]);
  const [last30days, setLast30days] = useState([]);
  const [others, setOthers] = useState([]);
  const [, setPage] = useState(1);

  function handleIntersect(target) {
    if (target.isIntersecting && loadMore) {
      setLoadMore(false);
      setPage((prevPage) => {
        let nextPage = prevPage + 1;
        loadMoreConversation(nextPage).then((nextList) => {
          if (nextList.length > 0) {
            setLoadMore(true);
          }
        });
        return nextPage;
      });
    }
  }

  useEffect(() => {
    if (conversations.length === 0) return;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(todayDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const thirtyDaysAgo = new Date(todayDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const todaySessions = [];
    const last7daysSessions = [];
    const last30daysSessions = [];
    const otherSessions = [];

    conversations
      .map((c) => ({ ...c, lastPrompt: new Date(c.lastPrompt) }))
      .sort((c1, c2) => c2.lastPrompt - c1.lastPrompt)
      .forEach((conversation) => {
        if (conversation.lastPrompt >= todayDate) {
          todaySessions.push(conversation);
        } else if (conversation.lastPrompt >= sevenDaysAgo) {
          last7daysSessions.push(conversation);
        } else if (conversation.lastPrompt >= thirtyDaysAgo) {
          last30daysSessions.push(conversation);
        } else {
          otherSessions.push(conversation);
        }
      });

    setToday(todaySessions);
    setLast7days(last7daysSessions);
    setLast30days(last30daysSessions);
    setOthers(otherSessions);
  }, [conversations]);

  const ConversationListItem = ({ conversation }) => {
    return (
      <div className={classNames("sessions-list-item", loading && "disabled")} onClick={() => selectConversation(conversation)}>
        <span className="session-title" title={conversation.title}>
          {conversation.title}
        </span>
        {selectedConversation._id === conversation._id && <span className="session-active"></span>}
      </div>
    );
  };

  return (
    <div className={classNames("mascot-sidebar", !mascot.image && "bg-empty", sidebarOpen && "open")}>
      {/* Close button for mobile */}
      <div className="icon-btn close-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
        <i className="icon-close"></i>
      </div>

      {showBadge &&
        ((!currentOrganization._id && mascot._id && mascot.hideLogo !== true) ||
          (currentOrganization &&
            currentOrganization._id &&
            (!currentOrganization.features || currentOrganization.features.hideLogo !== true))) && (
          <div className="wiseox-badge">
            <a href="https://wiseox.com" className="logo" target="_blank" rel="noreferrer">
              <img src={Logo} alt="WiseOx Logo" />
            </a>

            <div className="wiseox-badge-links">
              <a href="https://wiseox.com/legal/terms-of-use" className="meta small" target="_blank" rel="noreferrer">
                Terms
              </a>
              <a href="https://wiseox.com/legal/privacy" className="meta small" target="_blank" rel="noreferrer">
                Privacy
              </a>
            </div>
          </div>
        )}

      <div className="mascot-sidebar-content">
        {!editing && isAuthenticated && mascot.ownMascot && !loading && (
          <Link to="/" className="to-home">
            <i className="icon-chevron-left"></i>
          </Link>
        )}
        {!editing && isAuthenticated && mascot.ownMascot && loading && (
          <div className="to-home disabled">
            <i className="icon-chevron-left"></i>
          </div>
        )}
        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            {loading && (
              <div className="thinking">
                <Loader></Loader>
              </div>
            )}
            <MascotImage mascot={mascot._id ? mascot : mascotInit}></MascotImage>
          </div>

          {mascot.name && (
            <div className="mascot-heading">
              <h2>{mascot.name}</h2>
              <p>{mascot.description}</p>
            </div>
          )}

          {mascotInit && (
            <div className="mascot-heading">
              <h2>{mascotInit.name}</h2>
              <p>{mascotInit.description}</p>
            </div>
          )}
        </div>

        <div className="mascot-actions">
          {isAuthenticated && mascot.ownMascot && mascot._id && (
            <span
              className={classNames("mascot-action", loading && "disabled")}
              onClick={() => history.push(`/mascot/${mascot._id}/edit/info`)}
            >
              <i className="icon-edit"></i>
            </span>
          )}

          {isAuthenticated &&
            mascot.ownMascot &&
            mascot._id &&
            currentOrganization.features &&
            currentOrganization.features.insights && (
              <span
                className={classNames("mascot-action")}
                onClick={() => history.push(`/mascot/${mascot._id}/insights/overview`)}
              >
                <i className="icon-graph"></i>
              </span>
            )}

          {mascot.public && (
            <span
              className={classNames("mascot-action", !mascot.published && "disabled")}
              onClick={() => setShareModalOpen(true)}
            >
              <i className="icon-share"></i>
            </span>
          )}

          {mascot._id && (
            <span
              className={classNames(
                "mascot-action",
                (loading || (selectedConversation && selectedConversation._id === "new") || !mascot.published) && "disabled"
              )}
              onClick={() => createConversation()}
            >
              <i className="icon-new-chat"></i>
            </span>
          )}
        </div>

        {!mascotInit && (
          <>
            {!mascot.published || !conversations ? (
              <div className="sessions-container">
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "74%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "54%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "94%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "82%" }}></span>
                </div>
              </div>
            ) : (
              <div className="sessions-container">
                {today.length > 0 && <div className="sessions-list-separator">Today</div>}
                {today.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {last7days.length > 0 && <div className="sessions-list-separator">Last 7 Days</div>}
                {last7days.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {last30days.length > 0 && <div className="sessions-list-separator">Last 30 Days</div>}
                {last30days.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {others.length > 0 && <div className="sessions-list-separator">Older</div>}
                {others.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                <div ref={lazyLoader} />
              </div>
            )}
          </>
        )}

        {mascot._id && (
          <Modal title="Share Mascot" size="small" isOpen={shareModalOpen} close={() => setShareModalOpen(false)}>
            <div className="input-group">
              <label>Direct link to mascot</label>
              <input type="text" readOnly={true} value={`${window.location.origin}/mascot/${mascot._id}`}></input>
            </div>

            <button
              onClick={() => {
                toast.promise(navigator.clipboard.writeText(`${window.location.origin}/mascot/${mascot._id}`), {
                  success: "Copied to clipboard",
                  error: "Error copying to clipboard",
                });
              }}
            >
              Copy Link
            </button>
          </Modal>
        )}
      </div>
      <div className="mascot-sidebar-bg-wrapper">
        <div
          className="mascot-sidebar-bg"
          style={
            (mascot.image || mascotInit) && {
              backgroundImage: `url(${mascot.image || mascotInit.image})`,
            }
          }
        ></div>
      </div>
    </div>
  );
}
